<template>
	<div v-if="loaded">
		<h1>
			Welcome to<br />EAACI Hybrid Congress 2024<template v-if="name">, {{ name }}!</template>
		</h1>
		<dl>
			<dt>Speciality:</dt>
			<dd>{{ userData.speciality && userData.speciality.name }}</dd>
			<dt>Profession:</dt>
			<dd>{{ userData.user_profession && userData.user_profession.name }}</dd>
			<dt>Working Place:</dt>
			<dd>{{ userData.organization_type && userData.organization_type.name }}</dd>
			<dt>Country</dt>
			<dd>{{ userData.user_country && userData.user_country.name }}</dd>
		</dl>

		<div class="field">
			<strong>Do you have a license to prescribe a medicine?</strong>
			<input id="prescriber" v-model="userData.prescriber" type="checkbox" name="prescriber" class="switch is-rounded" disabled />
			<label for="prescriber">{{ userData.prescriber ? 'Yes' : 'No' }}</label>
		</div>
		<div class="field">
			<strong>Are you employed by a pharmaceutical company?</strong>
			<input id="pharmaEmployee" v-model="userData.pharmaEmployee" type="checkbox" name="pharmaEmployee" class="switch is-rounded" disabled />
			<label for="pharmaEmployee">{{ userData.pharmaEmployee ? 'Yes' : 'No' }}</label>
		</div>

		<template v-if="hasCompleteInfo">
			<label class="card-checkbox">
				<span class="input-wrapper">
					<input v-model="agree" type="checkbox" />
				</span>
				<span>
					I hereby confirm that the above details are accurate and true to my knowledge.
				</span>
			</label>

			<div class="buttons">
				<button class="button is-primary" :disabled="!agree" @click="profileCheck">Confirm</button>
			</div>

			<p>
				Alternatively, if any of the above details are not accurate,<br />amend your details in your Profile Area by clicking
				<router-link :to="{ name: 'EditProfile' }">here</router-link>.
			</p>
		</template>
		<template v-else>
			<p>
				Some of the information is missing, please amend your details in your Profile Area.
			</p>
			<div class="buttons">
				<router-link :to="{ name: 'EditProfile' }" class="button is-primary">Fill missing details</router-link>
			</div>
		</template>
	</div>
</template>

<script>
	import platformSettings from '../utils/platformSettings';
	import $http from '../utils/http';
	import session from '../utils/session';

	export default {
		name: 'ProfileCheckModal',
		props: {
			to: Object,
		},
		data() {
			return {
				platformSettings: platformSettings.state,
				userData: {},
				agree: false,
				loaded: false,
			};
		},
		computed: {
			name() {
				if (this.userData?.firstName) return this.userData.firstName;
				if (this.userData?.lastName) return this.userData.lastName;
				return false;
			},
			hasCompleteInfo() {
				if (this.userData?.speciality && this.userData?.user_profession && this.userData?.organization_type && this.userData?.user_country) return true;
				return false;
			},
		},
		mounted() {
			$http.get(`/user-info`).then(({ data }) => {
				this.userData = data;
				this.loaded = true;
			});
		},
		methods: {
			profileCheck() {
				$http.put('/user-check', {}).then(data => {
					session.updateCheck(true);
					this.$emit('close');
				});
			},
		},
	};
</script>
